import * as React from 'react';

import Carousel from 'react-bootstrap/Carousel';

import photo1 from '../../assets/engagement-event-5.jpeg';
import photo2 from '../../assets/engagement-event-4.jpeg';
import photo3 from '../../assets/engagement-event-2.jpeg';

const PhotoGallery = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" src={photo1} />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={photo2} />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={photo3} />
      </Carousel.Item>
    </Carousel>
  );
};

export default PhotoGallery;
