import { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import TRANSLATIONS from '../translations';

import { LanguageContext } from '../LanguageContext';

import PhotoGallery from '../ui/PhotoGallery';

const Home = () => {
  return (
    <Container>
      <Row id="home">
        <h2>We're getting married!</h2>
        <PhotoGallery />
        <p>
          We're excited to be sharing and celebrating with you. Please join us for our marriage in
          Antalya on June 17, 2023.
        </p>
      </Row>
    </Container>
  );
};

export default Home;
