import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import PageHeader from '../ui/PageHeader';
import PhotoGallery from '../ui/PhotoGallery';

const OurStory = () => {
  return (
    <Container>
      <Row id="our-story">
        <PageHeader title="Our Story" />
        {/* <p>We met in San Francisco and have been inseparable since. We ar</p> */}
        <PageHeader title="Some Photos" />
        <PhotoGallery />
      </Row>
    </Container>
  );
};

export default OurStory;
