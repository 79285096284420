import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

export const NavRow = styled(Row)`
  text-align: center;
  margin-top: 16px;

  a {
    color: #212529;
    text-decoration: none;
  }
`;

export const NavTitle = styled.h1`
  font-family: 'Charm', cursive;
`;

export const SubNavTitle = styled.h2`
  font-size: 20px;
  font-weight: 300;
`;

export const NavigationContainer = styled(Container)`
  && {
    justify-content: flex-end;
  }
`;
