const TRANSLATIONS = {
  english: {
    home: 'Home',
    weddingDetails: 'Wedding Details',
    rsvp: 'RSVP',
    registryExternal: 'Registry (external)',
    rsvpDetails: 'We ask that you please RSVP by June 1, 2023.',
    rsvpNameLabel: 'Name (required)',
    rsvpNamePlaceholder: 'Name',
    rsvpEmailLabel: 'Email Address (required)',
    rsvpEmailPlaceholder: 'Email Address',
    rsvpNumberOfGuestsLabel: 'Number of Guests (optional)',
    rsvpPhoneNumberLabel: 'Phone Number (optional)',
    rsvpPhoneNumberPlaceholder: 'Phone Number',
    rsvpMessageLabel: 'Message',
    rsvpMessagePlaceholder: 'Enter Message (optional)',
    rsvpAttendingLabel: 'Attending?',
    rsvpSubmit: 'Submit',
    rsvpSent: 'Sent',
    english: 'English',
    turkish: 'Turkish',
    homeTitle: "We're getting married!",
  },
  turkish: {
    home: 'Davetiye',
    weddingDetails: 'Nerede ve Ne zaman',
    rsvp: 'LCV',
    registryExternal: 'İhtiyaç listesi',
    rsvpDetails: '1 Haziran 2023e kadar katılımınızı bildirmenizi rica ediyoruz.',
    rsvpNameLabel: 'Adınız(Lütfen boş bırakmayınız)',
    rsvpNamePlaceholder: 'Ad',
    rsvpEmailLabel: 'Email adresiniz (Lütfen boş bırakmayınız)',
    rsvpEmailPlaceholder: 'Email adresiniz',
    rsvpNumberOfGuestsLabel: 'Kaç kişi katılacaksınız?',
    rsvpPhoneNumberLabel: 'Telefon Numaranız',
    rsvpPhoneNumberPlaceholder: 'Telefon Numaranız',
    rsvpMessageLabel: 'Bize mesajınız var mı?',
    rsvpMessagePlaceholder: 'Bize mesajınız',
    rsvpAttendingLabel: 'Katılıyor musunuz?',
    rsvpSubmit: 'Gönder',
    rsvpSent: 'İletildi.',
    english: 'İngilizce',
    turkish: 'Türkçe',
  },
};

export default TRANSLATIONS;
