import { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { Formik } from 'formik';
import * as Yup from 'yup';

import PageHeader from '../ui/PageHeader';

import axios from 'axios';

import TRANSLATIONS from '../translations';

import { LanguageContext } from '../LanguageContext';

import * as S from './RSVP.styled';

const RSVP = () => {
  const [showToast, setShowToast] = useState(false);

  const { language } = useContext(LanguageContext);

  const translated = TRANSLATIONS[language];

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Name is too short.')
      .max(200, 'Name is too long.')
      .required('Name is required.')
      .trim(),
    email: Yup.string()
      .email('Must be a valid email address.')
      .required('Email is required.')
      .trim(),
    numberOfGuests: Yup.number()
      .positive('Number of guests cannot be negative.')
      .integer(),
    phoneNumber: Yup.string()
      .max(50, 'Invalid phone number.'),
    message: Yup.string().max(1000, 'Message cannot be more than 1000 characters.'),
    attending: Yup.bool()
  });

  const handleRsvpSubmit = async (formValues) => {
    if (!formValues) throw new Error('Form values cannot be empty');
    if (!formValues.email || !formValues.name) throw new Error('Name and email are required form field values.');
    await axios.post('https://yt-qsgpcny5va-uc.a.run.app/rsvp-submit',
      JSON.stringify(formValues), {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  };

  return (
    <Container>
      <Row id="rsvp">
        <ToastContainer className="p-3" position="bottom-center">
          <Toast onClose={() => setShowToast(false)} show={showToast} delay={6000} autohide>
            <Toast.Header>
              <strong className="me-auto">{translated['rsvpSent']}</strong>
            </Toast.Header>
            <Toast.Body>Your RSVP submission was sent!</Toast.Body>
          </Toast>
        </ToastContainer>
        <PageHeader title={translated['rsvp']} />
        <p>{translated['rsvpDetails']}</p>
        <S.FormContainer>
          <Formik
            validationSchema={formSchema}
            initialValues={{
              name: '',
              email: '',
              numberOfGuests: 1,
              phoneNumber: '',
              message: '',
              attending: true,
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                setSubmitting(true);
                await handleRsvpSubmit(values);
                setShowToast(true);
                resetForm();
              } catch (err) {
                console.log(err);
              } finally {
                setSubmitting(false);
              }
            }}>
            {({ values, handleSubmit, isSubmitting, handleChange, handleBlur, errors, touched }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group as={Col} className="mb-3 position-relative" controlId="formName">
                  <Form.Label>{translated['rsvpNameLabel']}</Form.Label>
                  <Form.Control
                    isInvalid={touched.name && errors.name}
                    isValid={touched.name && !errors.name}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={translated['rsvpNamePlaceholder']}
                    type="text"
                    value={values.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="formEmailAddress">
                  <Form.Label>{translated['rsvpEmailLabel']}</Form.Label>
                  <Form.Control
                    isInvalid={touched.email && errors.email}
                    isValid={touched.email && !errors.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={translated['rsvpEmailPlaceholder']}
                    type="email"
                    value={values.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formNumberGuests">
                    <Form.Label>{translated['rsvpNumberOfGuestsLabel']}</Form.Label>
                    <Form.Control
                      isInvalid={touched.numberOfGuests && errors.numberOfGuests}
                      name="numberOfGuests"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter # Guests"
                      type="number"
                      value={values.numberOfGuests}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.numberOfGuests}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formPhoneNumber">
                    <Form.Label>{translated['rsvpPhoneNumberLabel']}</Form.Label>
                    <Form.Control
                      isInvalid={touched.phoneNumber && errors.phoneNumber}
                      name="phoneNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={translated['rsvpPhoneNumberPlaceholder']}
                      type="tel"
                      value={values.phoneNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Form.Group as={Col} className="mb-3" controlId="formMessage">
                  <Form.Label>{translated['rsvpMessageLabel']}</Form.Label>
                  <Form.Control
                    isInvalid={errors.message}
                    as="textarea"
                    name="message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={translated['rsvpMessagePlaceholder']}
                    rows={2}
                    value={values.message}
                    maxLength={1000}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="formAttending">
                  <Form.Check
                    isInvalid={errors.attending}
                    checked={values.attending}
                    type="checkbox"
                    name="attending"
                    label={translated['rsvpAttendingLabel']}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.attending}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.attending}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {translated['rsvpSubmit']}
                </Button>
              </Form>
            )}
          </Formik>
        </S.FormContainer>
      </Row>
    </Container>
  );
};

export default RSVP;
