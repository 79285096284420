import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';

import * as S from './Navigation.styled';

import TRANSLATIONS from '../translations';

import { LanguageContext } from '../LanguageContext';

const Navigation = () => {
  const { language, setLanguage } = useContext(LanguageContext);

  const translated = TRANSLATIONS[language];

  return (
    <Container>
      <S.NavRow>
        <S.NavTitle>
          <NavLink to="/">Yaprak & Tim</NavLink>
        </S.NavTitle>
        <S.SubNavTitle>Antalya, Türkiye &bull; 06/17/2023</S.SubNavTitle>
      </S.NavRow>
      <Row>
        <Navbar expand="lg" collapseOnSelect>
          <S.NavigationContainer>
            <Navbar.Toggle aria-controls="navbar-nav" />
            <Navbar.Collapse id="navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={NavLink} to="/" eventKey="1">
                  {translated['home']}
                </Nav.Link>
                <Nav.Link as={NavLink} to="/wedding-details" eventKey="2">
                  {translated['weddingDetails']}
                </Nav.Link>
                {/* <Nav.Link as={NavLink} to="/our-story" eventKey="3">
                  Our Story
                </Nav.Link> */}
                <Nav.Link as={NavLink} to="/rsvp" eventKey="3">
                  {translated['rsvp']}
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  to="https://www.amazon.com/wedding/share/yapraktim"
                  target="_blank"
                  eventKey="4">
                  {translated['registryExternal']}
                </Nav.Link>
              </Nav>
              <Navbar.Text className="justify-content-end">
                <Button onClick={() => setLanguage('english')} variant="link">
                  {translated['english']}
                </Button>{' '}
                |
                <Button onClick={() => setLanguage('turkish')} variant="link">
                  {translated['turkish']}
                </Button>
              </Navbar.Text>
            </Navbar.Collapse>
          </S.NavigationContainer>
        </Navbar>
      </Row>
    </Container>
  );
};

export default Navigation;
