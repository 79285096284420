import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import PageHeader from '../ui/PageHeader';

const WeddingDetails = () => {
  return (
    <Container>
      <Row id="wedding-details">
        <PageHeader title="Wedding Details" />
        <h2>General Information</h2>
        <p>
          Our wedding will outdoors and located at Arma restaurant in Antalya on June 17, 2023.{' '}
        </p>
        <h2>Schedule</h2>
        <ul>
          <li>7:00 pm Cocktail Hour</li>
          <li>8:00 pm Ceremony</li>
          <li>8:30 pm Dinner</li>
          <li>9:30 pm Music</li>
          <li>12:00 am Conclusion</li>
        </ul>
        <h2>Location</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.2203364434104!2d30.698286515651496!3d36.88507687993113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c390048a9000a5%3A0x94d7569e316ea62e!2sArma%20Restaurant!5e0!3m2!1sen!2sus!4v1673821205782!5m2!1sen!2sus"
          width="800"
          height="400"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
        <h2>FAQ</h2>
        <h3>Food</h3>
        <p>
          Our celebration will include a 6 course meal with a Steak entree. We can only accomodate
          few subsitutions, so please let us know ahead of time in the RSVP message or by letting us
          know directly.
        </p>
        <h3>Parking & Transporation</h3>
        <p>
          We will have some limited parking available on-site. We recommend taking transporation
          we'll offer with more details to come.
        </p>
        <h3>Weather</h3>
        <p>Weather this time of year will be warm (can be up to 90°F).</p>
      </Row>
    </Container>
  );
};

export default WeddingDetails;
