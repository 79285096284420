import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { createBrowserRouter, RouterProvider, Outlet, Routes, Route, Link } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ErrorPage from './ErrorPage';
import Home from './Home';
import Navigation from './Navigation';
import OurStory from './OurStory';
import RSVP from './RSVP';
import WeddingDetails from './WeddingDetails';

const retrieveLanguage = () => {
  if (/^tr\b/.test(navigator.language)) {
    return 'turkish';
  }
  return 'english';
};

const Root = () => {
  const retrievedLanguage = retrieveLanguage();

  const [language, setLanguageState] = React.useState(retrievedLanguage);

  const setLanguage = (language) => {
    setLanguageState(language);
  };

  const contextValue = React.useMemo(() => ({ language, setLanguage }), [language]);

  return (
    <LanguageContext.Provider value={contextValue}>
      <Navigation />
      <div id="details">
        <Outlet />
      </div>
    </LanguageContext.Provider>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/wedding-details',
        element: <WeddingDetails />,
      },
      {
        path: '/our-story',
        element: <OurStory />,
      },
      {
        path: '/rsvp',
        element: <RSVP />,
      },
    ],
  },
]);

const App = () => {
  return (
    <Container>
      <RouterProvider router={router} />
    </Container>
  );
};

export default App;
